@import "./colors.scss";
@import "./variables.scss";
@import "./mixins.scss";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap&subset=latin-ext");

@font-face {
    font-family: "Gloss_And_Bloom";
    src: url("../assets/Gloss_And_Bloom.eot");
    src: local("☺"), url("../assets/Gloss_And_Bloom.woff") format("woff"),
        url("../assets/Gloss_And_Bloom.ttf") format("truetype"), url("../assets/Gloss_And_Bloom.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

body {
    min-width: 320px;
    font-family: "Poppins", sans-serif !important ;
    font-size: 14px !important;
    margin: 0 auto;
}

.stop-scroll {
    height: 100vh;
    overflow-y: hidden;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @include media-breakpoint-up(md) {
        width: 1000px;
    }

    &--enquiry {
        @include media-breakpoint-up(xl) {
            max-width: 1300px;
            width: 100%;
        }
    }
}
.headroom-wrapper,
.headroom.headroom--unfixed {
    width: 100% !important;
    position: relative !important;
    z-index: 5 !important;
    max-width: 100%;
    &.absolute {
        position: absolute!important;
    }
}
.content {
    position: relative;
    z-index: 1;
}
.slick-slider {
    .slick-arrow {
        .slick-prev {
        }
        .slick-next {
        }
    }
    .slick-list {
        .slick-track {
            .slick-slide {
            }
        }
    }

    &_Article {
        .slick-arrow {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ffffff;
            font-size: 0;
            box-shadow: -2px 2px 7px 0 rgba(0, 0, 0, 0.25);
            z-index: 1;
            transition: box-shadow $transition;
            margin-top: -11px;

            &:before,
            &:after {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: #c0cacf;
                font-size: 20px;
                opacity: 1;
            }

            &:hover {
                background-color: white;
                box-shadow: -2px 2px 7px 0 rgba(0, 0, 0, 0.5);
            }

            &.slick-prev {
                left: -65px;

                &:before {
                    content: "\f053";
                }
            }
            &.slick-next {
                right: -65px;

                &:before {
                    content: "\f054";
                }
            }
        }
        .slick-list {
            margin: 0px -17.5px;

            .slick-track {
                .slick-slide {
                    padding: 0px 17.5px;
                }
            }
        }
    }

    &_SliderTopic {
        padding-top: 7px;
        padding-bottom: 8px;

        .slick-list {
            margin: 0 -6px;

            .slick-track {
                .slick-slide {
                    padding: 0 6px;
                }
            }
        }
    }

    &_SliderCategory {
        padding-top: 16px;

        .slick-list {
            margin: 0 -47.5px;

            .slick-track {
                .slick-slide {
                    padding: 0 47.5px;
                }
            }
        }
    }
}

.nav-item--articles {
    .nav-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        border-bottom: none;
        margin-bottom: 63px;

        .nav-item {
            display: flex;
            align-items: center;
            background-color: #e5e5e5;
            color: #10151b;
            border: none;
            height: 40px;
            margin-bottom: 0;

            &:first-child {
                padding-left: 22px;
                border-radius: 20px 0 0 20px;
            }

            &:last-child {
                padding-right: 22px;
                border-radius: 0 20px 20px 0;
            }

            &.active {
                background-color: #10151b;
                color: #ffffff;
            }
        }
    }
}

.modal-header {
    padding: 0 0 26px 0;

    p {
        font-size: 14px;
    }
}

.modal-body {
    padding: 0;
}

.modal-open {
    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        z-index: 6;
    }
}

.form-label {
    margin-bottom: 2px;
}

.color-secondary {
    color: var(--lime);
}

// validation
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-position: center right 10px;
}
//

// captcha
.g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
}

.notification-success {
    background: #84e5dc;
    color: #10151b;
}
