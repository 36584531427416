.readMoreButton {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #E8388A;
  
  &:hover {
    cursor: pointer;
  }
}

.readMoreTextContent {
  overflow: hidden;
}