.container.wrapper {
  .component {
    .input {
      font-size: 20px;

      &::placeholder {
        font-size: 20px;
      }
    }
  }
}
