@import './../../styles/variables.scss';
@import './../../styles/mixins.scss';

.app {
    // padding-top: 56px;
    min-height: 100vh;
    display: flex;
    background: white;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @include media-breakpoint-up(md) {
        // padding-top: 85px;
        padding-top: 0;
    }

    button:active,
    button:focus {
        outline: none;
    }
}


.my-tooltip-magenta  .ant-tooltip-inner {
    background-color: #F708D8;
    border-radius: 28px;
    font-size: 10px;
    padding: 5px 14px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
}

.my-tooltip-magenta.no-arrow  .ant-tooltip-arrow::before {
    content: '';
}
.my-tooltip-magenta  .ant-tooltip-arrow::before {
    background-color: #F708D8;
}

button:active,
button:focus {
    outline: none;
}