@import '~bootstrap/scss/mixins/_breakpoints.scss';

$magenta: #E8388A;
$magentaPale: #fcf2f6;
$gray: #ABB0BE;
$grayPale: #ccd1df;
$darkGray: #475668;
$dashboardBackground: #293749;
$dashboardHeader: #20D5C4;

$transitionDuration: 0.5s;

%buttonBasic {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  border-radius: 40px;
  opacity: 1;
  padding: 7px 20px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  &:focus {
    outline: unset;
  }
}

@mixin buttonMagenta {
  @extend %buttonBasic;
  color: white;
  background-color: $magenta;
  border: 1px solid $magenta;

  &:hover {
    background: white;
    color: $magenta;
    transition: $transitionDuration;
  }

  &:disabled, &:hover:disabled {
    color: white;
    background-color: $magentaPale;
    border-color: $magentaPale;
  }
}

@mixin buttonMagentaTransparent {
  @extend %buttonBasic;
  color: $magenta;
  background-color: white;
  border: 1px solid $magenta;

  &:hover {
    background: $magenta;
    color: white;
    transition: $transitionDuration;
  }

  &:disabled, &:hover:disabled {
    color: $magentaPale;
    background-color: white;
    border-color: $magentaPale;
  }
}

@mixin buttonUpload {
  @extend %buttonBasic;
  color: black;
  background-color: #F1F5F6;
  border: 1px solid black;

  &:hover {
    background: white;
    color: black;
    transition: $transitionDuration;
  }
}

@mixin buttonGray {
  @extend %buttonBasic;
  color: white;
  background-color: $darkGray;
  border: 1px solid $darkGray;

  &:hover {
    background: white;
    color: $darkGray;
    transition: $transitionDuration;
  }

  &:disabled, &:hover:disabled {
    color: white;
    background-color: $grayPale;
    border-color: $grayPale;
  }
}

@mixin buttonGrayInverse {
  @extend %buttonBasic;
  color: $darkGray;
  background-color: white;
  border: 1px solid $darkGray;

  &:hover {
    background: $darkGray;
    color: white;
    transition: $transitionDuration;
  }

  &:disabled, &:hover:disabled {
    color: $gray;
    background-color: white;
    border-color: $gray;
  }
}

@mixin buttonGrayTransparent {
  @extend %buttonBasic;
  color: $gray;
  background-color: transparent;
  border: 1px solid $gray;

  &:hover {
    background: $gray;
    color: white;
    transition: $transitionDuration;
  }
}


@mixin topicButtonMixin($color: white, $background-color: #e9398a) {
  color: $color;
  background-color: $background-color;
  border: 1px solid $background-color;
  transition-property: color, background-color;
  transition-duration: .3s;

  &:hover {
    color: $background-color;
    background-color: $color;
  }
}
