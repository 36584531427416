:root {
    /* helpers */
    --primary: #cc66ff;
    --secondary: #ff00ff;
    --danger: #ff0084;
    --lime: #1bc3b3;
    --outliner: #00dac3;
    --dark-standart: #10151b;
    --dark-secondary: #606060;
    --light-lime: #f7fafb;
    --grass: #16d4c2;
    --coral: #E8388A;
    --grey-back: #E9EAEC;
    /* calendar */
    --light-grey-color: #f4f6f7;
    /* Progress */
    --grey-progress: #ddd;
    --black-progress: #32d4c2;
    --blue-progress: #cc66ff;
    --radio-size: 26px;
    --inner-radio-size: 12px;
    /* Button */
    --blue-color: #32d4c2;
    --grey-btn: #d0d0d0;
    --grey-btn-bg: #DDDFE5;
    /* myInput */
    --border-color: #a4a4a4;
    /* Text */
    --black-lg: #10151b;
    --black-btn: #293749;
    --text-semidark: #797979;

    /* Time Picker, Calendar*/
    --green-primary: #16d4c2;
    --blue-primary: #ff06ff;
    --grey-inactive: #cacccd;
    --grey-buttons: #a4a4a4;
    --grey-header: #606060;
    --grey-md: #ccc;
    --black-md: #333333;
    --grey-lt: #f4f6f7;

    --font-size-lg: 14px;
    --font-size-sm: 10px;
    --font-size-12: 12px;
    --font-size-11: 11px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-md: 13px;

    --size-text: 16px;
    --size-h2: 32px;
    --size-h1: 40px;

    --font-size-header: 14px;

    --display-time-size: 22px;
    --am-size: 12px;

    /* footer */
    --black-footer: #15171a;
    --grey-footer: #d1d1d1;
    --grey-footer-nav: #919191;
    --grey-footer-border: #2a2d30;

    --profile-preview-color: #f7fafb;
    --info-color: #606060;
    --tag-color: var(--green-primary);
    --icon-color: #626669;

    --modal-opacity: 0.6;
}
