.main {
  .paper {

    .searchSection {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      column-gap: 30px;
      padding: 10px;
    }

    .actionsCellContent {
      display: flex;
      justify-content: space-between;

      .verifyButton, .deleteButton {
        cursor: pointer;
      }
    }

    .paginationContainer {
      display: flex;
    }
  }

  h1 {
    text-align: left;
    font-size: 40px;
    color: #43425D;
  }

  .colWidth10 {
    width: 10%;
  }

  .colWidth20 {
    width: 20%;
  }
}
