@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

// this code is fore old version of footer.

.footer {
    background: var(--black-footer);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--grey-footer);
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        height: 352px;
        padding-top: 66px;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.row {
    // margin: auto;

    // height: 100%;
    // width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    // @include media-breakpoint-up(md) {
    //     display: flex;
    // }
}

.info {
    position: relative;
    text-align: center;
    max-width: 270px;
    margin: 0 auto;
    &:after {
        position: absolute;
        content: "";
        display: block;
        height: 200px;
        border-right: solid 1px var(--grey-footer-border);
        right: -5vw;
        top: 0;
    }
    // @include media-breakpoint-up(md) {
    //     max-width: 350px;
    //     padding-right: 97px;
    // }
    // @include media-breakpoint-up(lgg) {
    //     max-width: 371px;
    //     padding-right: 115px;
    // }
    // @include media-breakpoint-up(xl) {
    //     max-width: 445px;
    //     padding-right: 183px;
    // }
    // @include media-breakpoint-up(xxl) {
    //     max-width: 463px;
    //     padding-right: 200px;
    // }
}

.topBox {
    margin-bottom: 8px;
    margin-top: -3px;

    h3 {
        font-weight: 300;
        font-size: 16px !important;
        line-height: normal !important;
        margin-bottom: 0 !important;
        color: var(--grey-footer-nav);
    }
}

.topBoxBtn {
    width: 100%;

    @include media-breakpoint-up(xxl) {
        font-size: 13px;
    }

    &_Btn {
        max-width: 200px;
        width: 100%;

        @include media-breakpoint-up(lgg) {
            font-size: 13px;
        }
    }
}

.container {
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 980px;
    height: 100%;
}

#subscribe p {
    font-size: var(--font-size-12);
    color: var(--grey-footer-nav);
}

p {
    font-size: var(--font-size-11);
}

h3 {
    font-size: var(--font-size-16);
}

h4 {
    font-size: var(--font-size-md);
}

.subscribe {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        font-size: var(--font-size-12);
        color: var(--grey-footer-nav);
    }
}

.subscbribeItem {
    margin: 0 0 34px 0;
    // width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    font-size: 13px;
    line-height: 24px;
}

#nav {
    padding-top: 34px;

    /* flex: 2; */
    color: var(--grey-footer-nav);

    line-height: 2;
    // width: 100%;
    justify-content: space-between;
    display: flex;
    max-width: 500px;
    // @include media-breakpoint-up(md) {
    // justify-content: center;
    // }

    p {
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 2px;
        }
    }
    h4 {
        color: var(--grey-footer-nav);
    }
}

.navTitle {
    font-weight: 400;
    margin-bottom: 22px;
}

.col {
    text-align: center;

    @include media-breakpoint-up(md) {
        margin: 0 10px 0 10px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.sidebar {
    width: 100%;
    margin-top: 30px;

    @include media-breakpoint-up(sm) {
        margin-top: 0;
    }
    @include media-breakpoint-up(md) {
        max-width: 170px;
        //margin-left: 36px;
        text-align: left;
    }
    @include media-breakpoint-up(lgg) {
        max-width: 166px;
    }

    .navTitle {
        font-weight: normal;
    }

    & > * {
        line-height: 26px;
        margin-top: -7px;
    }
}

.navcol {
    text-align: left;

    // @include media-breakpoint-up(md) {
    //     flex: 1;
    //     text-align: left;
    // }
    // @include media-breakpoint-up(lgg) {
    //     margin: 0 40px 0 40px;
    // }
    // @include media-breakpoint-up(lgg) {
    //     margin: 0 54px 0 38px;
    // }

    // @include media-breakpoint-up(xl) {
    //     margin: 0 105px 0 23px;
    // }

    // @include media-breakpoint-up(xxl) {
    //     margin: 0 119px 0 19px;
    // }

    // &:first-child {
    //     @include media-breakpoint-up(lgg) {
    //         margin-left: 0;
    //     }
    // }
}

.col1 {
    composes: col;

    margin-left: 5px;
    @include media-breakpoint-up(lgg) {
    }
}

.col2 {
    composes: col;
    width: 51.5%;

    //     @include media-breakpoint-up(md) {
    //         min-width: 550px;
    //     }
    //     @include media-breakpoint-up(xl) {
    //         min-width: 700px;
    //     }
}

.social {
    display: flex;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 10px;
    margin-left: -7px;

    @include media-breakpoint-up(lgg) {
        margin-left: 0;
    }
}

.socialIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 7px;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    color: var(--black-footer);
    border-radius: 50%;
    background: #fff;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
