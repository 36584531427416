.button {
  padding: 10px 98px;
  border: 0;
  background-image: url('../../assets/stripe-connect-button.svg');
  background-size: cover;
  transition-duration: 0.3s;

  &.loading {
    opacity: 0.5;
  }
}
