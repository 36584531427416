@import '../styles/mixins.scss';

$aqua: #08C8B8;
$fontColor: #293749;
$pink: #E8388A;
$tabletBreakpoint: 768px;


.container {
   height: calc(100vh - 40px);
   padding-top: 100px;

   @media (max-width: $tabletBreakpoint) {
       height: calc(100vh - 25px);
       padding-top: 65px;
       padding-left: 10px;
       padding-right: 10px;
   }
}

.content {
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 100%;
  max-width: 1170px;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;

  @media (max-width: $tabletBreakpoint) {
    grid-template-columns: auto;

    &_chatPicked {
        grid-template-columns: auto;
      }
  }
}

.list {
    display: flex;
    flex-direction: column;
    &_container {
        overflow-y: auto;
        background-color: #f4f6f7;
        flex-grow: 1;
    }
}

.search {
    min-height: 98px;
    display: flex;
    align-items: center;
    padding-right: 15px;

    @media (max-width: $tabletBreakpoint) {
        min-height: 50px;
        padding: 0 10px;
    }

    &_input {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 20px;
        max-width: 205px;
        height: 40px;

        @media (max-width: $tabletBreakpoint) {
            max-width: unset;
            width: 100%;
        }

        & > div {
            height: 40px;
        }

        fieldset {
            border: 0;
        }

        input::placeholder {
            color: #a4a4a4;
            font-size: 13px;
            line-height: 17px;
        }
    }
}

.dialog {
    display: flex;
    justify-content: space-between;
    border-left: 3px solid #f4f6f7;
    border-bottom: 1px solid #d1d1d1;
    padding: 23px;
    cursor: pointer;

    &_photo {
        position: relative;
        width: 38px;
        height: 38px;
        flex-shrink: 0;
        margin-right: 15px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        .online {
            position: absolute;
            bottom: 0px;
            right: -1px;
            width: 10px;
            height: 10px;
            background-color: springgreen;
            border: 1px solid white;
            border-radius: 5px;
        }
    }
    &_text {
        flex-grow: 1;
        font-size: 12px;
    }
    &_name {
        color: #10151b;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .channelName {
        color: #10151b;
    }

    &_message {
        color: $fontColor;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    &_attachmentLabel {
        color: $fontColor;
        font-style: italic;
    }

    &_indicator {
        align-self: flex-start;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        background-color: $pink;
        padding: 0 10px;
        border-radius: 9px;
    }

    &:hover, &_selected {
        background-color: #d2dfe5;
    }
}

.conversation {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: $tabletBreakpoint) {
        row-gap: 10px;
    }
}

.chatroom {
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    flex-grow: 1;
}

.chatInfo {
    min-height: 98px;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    row-gap: 20px;
    color: $fontColor;

    @media (max-width: $tabletBreakpoint) {
        flex-wrap: wrap;
        max-height: unset;
        min-height: unset;
        row-gap: 3px;
    }

    .leftColumn {
        display: flex;

        .enquiryInfo {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .user {
                display: flex;
                align-items: center;
                column-gap: 10px;
                max-height: 100%;
                overflow: hidden;

                @media (max-width: $tabletBreakpoint) {
                    justify-content: flex-start;
                    width: 100%;
                    max-width: unset;
                    font-size: 16px;
                }


                .img {
                    display: flex;
                    width: 48px;
                    height: 48px;

                    img {
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .name {
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 1em;

                    @media (max-width: $tabletBreakpoint) {
                        font-size: 16px;
                    }
                }

                .bio {
                    font-size: 15px;
                    opacity: 0.8;

                    @media (max-width: $tabletBreakpoint) {
                        font-size: 11px;
                    }
                }
            }

            .eventInfo {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                column-gap: 10px;

                .eventName {
                    font-size: 15px;
                    font-weight: 600;
                    flex-grow: 1;
                    padding-left: 60px;

                    @media (max-width: $tabletBreakpoint) {
                        padding-left: 0;
                    }
                }
            }
        }

        .back {
            display: none;
            transform: scaleX(-1);
            padding: 0 15px;

            @media (max-width: $tabletBreakpoint) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .bookingButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .bookingButton {
            text-align: right;

            .button {
                @include buttonMagenta;
            }
        }
    }
}
.log {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .voidFill {
        flex-grow: 1;
    }

    &_date {
        color: #10151b;
        font-size: 12px;
        opacity: 0.8;
        padding: 10px;
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
    }
}
.msg {
    display: flex;
    padding: 20px 75px 20px 15px;

    @media (max-width: $tabletBreakpoint) {
        padding: 20px 15px 10px 5px;
        align-items: end;
    }

    .files_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;

        .wrapper {
            border-radius: 5px;
            overflow: hidden;
        }
    }

    &_img {
        flex-shrink: 0;
        max-width: 40px;
        height: 40px;
        margin-right: 25px;
        @media (max-width: $tabletBreakpoint) {
            margin-right: 0;

            &.hidden {
                display: none;
            }
        }

        img {
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    &_text {
        color: #10151b;
        flex-grow: 1;

        @media (max-width: $tabletBreakpoint) {
            padding: 15px 23px;
            border-radius: 5px;
            background-color: #DCDFE0;
            margin: 0 40px 0 0;

            &.isMe {
                background-color: #F5F5F5;
                margin: 0 0 0 80px;
            }
        }
    }

    .header {
        display: flex;
        column-gap: 10px;
        margin-bottom: 8px;

        .name {
            font-size: 12px;
            font-weight: 600;

            @media (max-width: $tabletBreakpoint) {
                display: none;
            }
        }

        .date {
            font-size: 11px;
            line-height: 1.7em;
        }
    }

    .chatImage {
        width: 100%;
        max-width: 350px;
    }

    .chatFile {
        display: inline-block;
        color: white;
        background-color: #999;
        padding: 10px 20px;
        border-radius: 15px;

        &:hover {
            text-decoration: underline;
        }

        .icon {
            margin-right: 10px;
        }

        .fileName {

        }
    }

    &_msg {
        font-size: 14px;
        opacity: 0.6;
        white-space: pre-wrap;
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;
    flex-basis: 86px;
    flex-shrink: 0;
    border-top: 1.5px solid #DCDFE0;

    .attachedFiles {
        display: flex;
        flex-direction: column;
        align-items: start;
        row-gap: 5px;
        padding-top: 10px;
        padding-left: 55px;
        min-height: 44px;

        .fileItem {
            display: flex;
            background-color: #DCDFE0;
            padding: 5px;
            border-radius: 5px;

            .name {
                padding: 0 10px;
                line-height: 1.7em;
            }

            .delete {
                cursor: pointer;
                padding: 0 10px;

                &:hover {
                    color: $pink;
                }
            }
        }

    }
}

.input {
    display: flex;
    align-items: center;
    column-gap: 10px;
    bottom: 0;
    width: 100%;
    padding: 20px 30px 20px 10px;
    background-color: #fff;

    @media (max-width: $tabletBreakpoint) {
        padding: 20px 10px 20px 5px;
        column-gap: 5px;
    }

    &_inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .textarea {
        display: flex;
        flex-grow: 1;
        height: auto;

        .inputArea {
            resize: none;
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            transition-duration: 0.2s;
            transition-property: box-shadow, border;

            &:hover {
                border-color: $aqua;
            }

            &:focus-visible,
            &:focus {
                outline: 0;
                border-color: $aqua;
                box-shadow: 0 0 10px 1px adjust-color($aqua, $alpha: -0.7);
            }
        }
    }

    .attach {
        .fileInputButton {
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
            transition-property: color, background-color;
            transition-duration: 0.2s;

            &:hover {
                color: white;
                background-color: lightgrey;
            }
        }

        .fileInputElement {
            display: none;
        }
    }

    .send {
        &_button {
            color: white;
            cursor: pointer;
            border-width: 0;
            border-radius: 5px;
            padding: 10px 20px;
            background-color: #16d4c2;
            transition-duration: 0.2s;
            transition-property: background-color;

            &:hover {
                background-color: adjust-color(#16d4c2, $lightness: -4);
            }

            &_icon {
                transform: translate(0, -2px) rotate(-45deg);
            }

            .text {
                @media (max-width: $tabletBreakpoint) {
                    display: none;
                }
            }
        }
    }


}

@media (max-width: $tabletBreakpoint) {
    .mobileHidden {
        display: none;
    }
}

.loader {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.noDialogs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.detailsLink {
    margin-left: 10px;
    font-weight: 500;
    a {
        color: $aqua;

        &:hover, &:active {
            color: adjust-color($aqua, $lightness: 8);
        }
    }
}

.enquiryLoadSpinnerContainer {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .eventNameSpinner {
        color: $fontColor;
    }

    .inputSpinner {
        color: $aqua;
    }
}
