@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.header {
    position: fixed;
    top: 0;
    width: 100%;
    font-size: var(--font-size-header);
    z-index: 2;
    transition: box-shadow 0.5s;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: var(--dark-standart);
    transition: background-color 0.3s;

    &-regular {
        box-shadow: none;
        top: 0;
    }

    &-profile {
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);
        color: white;
    }

    .header_wrapper {
        position: relative;
        display: flex;
        padding: 16px 20px 18px 35px;
        justify-content: space-between;
    }

    .avatar {
        height: 50px;
        width: 50px;
        overflow: hidden;
        cursor: pointer;
        img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            max-height: 50px;
            max-width: 50px;
            object-fit: cover;
            margin: auto;
            object-position: 50% 50%;
        }
    }

    &.active {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    }
}

.header-light.header {
    position: relative;

    .groupTwo {
        color: #293749;

        &::after {
            border-bottom: 1.5px solid #293749;
            border-right: 1.5px solid #293749;
        }
    }
}

.logo {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);

    img {
        width: 238px;
        height: auto;
    }

    @media (max-width: 1280px) {
        max-width: 238px;
    }
}

%headerButton {
    height: 40px;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    margin-left: 26px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out, color 3s ease-in-out;

    &:focus,
    &:hover,
    &:active {
        outline: none;
    }

    @media (max-width: 1280px) {
        font-size: 12px;
        line-height: 42px;
        padding: 0 42px;
    }
}

.getStart {
    display: table-cell;
    vertical-align: middle;
    padding: 0 25px;
    height: 47px;

    @extend %buttonBasic;
    color: white;
    background-color: var(--grass);
    border: 1px solid var(--grass);

    &:hover {
      background: white;
      color: var(--grass);
      transition: 0.5s;
    }
}

.enquire {
    @extend %headerButton;
    background: var(--grass);
    color: #ffffff;

    &:hover {
        color: var(--grass);
        background: #303D4B;
    }
}

.group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 1280px) {
        li {
            .btnMenu,
            a {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}

.groupOne {
    composes: group;
}

.groupTwo {
    composes: group;
    margin: 4px 0 0;
    padding-left: 0;
    display: none;
    font-size: 14px;
    color: #fff;

    @include media-breakpoint-up(md) {
        display: flex;
        column-gap: 30px;
    }

    li {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        @media (max-width: 1280px) {
            font-size: 14px;
            line-height: 18px;
        }

        .btnMenu {
            color: #fff;
        }

        .btnMenu:hover, a:hover, .btnMenu:focus {
            color: var(--grass);
        }

        a {
            color: var(--grass);

            &:hover, &:focus {
                color: #fff;
            }
        }
    }
}

.menuGroup {
    composes: group;
}

.headerNav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    column-gap: 25px;

    flex-grow: 1;

    .btnMenu,
    a {
        color: #ffffff;
        position: relative;

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    @media (max-width: 1280px) {
        font-size: 14px;
        line-height: 18px;
        justify-content: space-between;
        column-gap: 10px;
    }
}

.group {
    li {
        display: flex;
        text-align: right;

        button {
            padding: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
.menu_userName {
    max-width: 200px;
    white-space: pre-line;
    text-align: right;
}

.btnMenu {
    font-weight: 600;
    font-size: 18px;
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }
}
.btnMargin {
    margin-right: 25px;
}

.popUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80vw;
    position: absolute;
    top: 10vh;
    right: 54.5px;
    border-radius: 5px;
    padding: 0px 4px 0px 4px;
    background: white;
    color: var(--grey-footer-text);
    font-size: var(--font-size-header);
    overflow: hidden;
    animation-name: fade-in;
    animation-duration: 500ms;
    border: 0.5px rgba(0, 0, 0, 0.1) solid;

    li {
        width: 100%;
        margin: 3px !important;
        padding: 3px 3px 5px 15px;
        border-radius: 1px;
        text-align: left;
        list-style: none;

        &:hover {
            background: var(--grey-lt);
        }
    }
}

.menuIcon {
    font-size: 23px;
    position: absolute;
    color: var(--black-md);

    &:hover {
        color: var(--grey-header);
    }
}

.nameArrow {
    display: inline-block;
    position: relative;
    padding-right: 15px;
    margin-right: 25px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    &::after {
        position: absolute;
        content: "";
        height: 7px;
        width: 7px;
        display: block;
        border-bottom: 1.5px solid #fff;
        border-right: 1.5px solid #fff;
        margin-left: 10px;
        bottom: 2px;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }

    //&--black::after {
    //    border-bottom-color: var(--dark-standart);
    //    border-right-color: var(--dark-standart);
    //}
}
.menu {
    position: absolute;
    padding: 22px 30px 20px;
    background-color: white;
    border: 1px solid #d7d7d7;
    border-radius: 15px;
    font-size: 12px;
    color: #10151b;
    line-height: 32px;
    top: 80%;
    right: 70px;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.1);
    .el {
        cursor: pointer;
        color: #10151b;
        a {
            color: #10151b;
        }
    }
    .line {
        height: 15px;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 10px;
        width: 200px;
    }
    .img_wrapper {
        display: inline-block;
        width: 30px;
        margin-right: 15px;
        text-align: center;

        img {
            width: 60%;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.header-regular,
.header-profile {
    .groupTwo {
        margin-top: 4px;
    }
}

.dropdownMenuItem {
    a {
        color: black;
        white-space: nowrap;

        &:hover {
            color: var(--grass);
        }
    }
}
