.speaker-card-wrapper {
    position: relative;
    width: 250px;
    display: flex;
    justify-content: center;
    margin-bottom: 34px;

    @media (max-width: 768px) {
        margin-bottom: 26px;
    }
}

.speaker-card-container {
    width: 224px;
    height: 420px;
    background-color: #ffffff;
    border-radius: 5px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.speaker-card-flip {
    padding-top: 18px;
    height: 230px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        height: 265px;
    }

    .speaker-card-back {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        width: 224px;
    }

    &:hover .speaker-card-back {
        @media (min-width: 768px) {
            pointer-events: initial;
            opacity: 1;
        }
    }

    .speaker-card-back__active {
        pointer-events: initial;
        opacity: 1;
    }
}

.speaker-card-avatar {
    width: 100%;
}

.speaker-card-image-container {
    width: 100%;
    height: 189px;
    border-radius: 5px;
    position: relative;
}

.speaker-card-shortlist-button {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: absolute;
    top: 9px;
    right: 7px;
    cursor: pointer;

    @media (max-width: 768px) {
        opacity: 0.75;
    }

    span {
        font-weight: bold;
        font-size: 18px;
    }

    img {
        width: 11px;
        height: 11px;
    }
}

.speaker-card-shortlist-back-button {
    background-color: rgba(232, 56, 138, 0.5);
    color: #ffffff;
}

.speaker-card-name {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 7px 0;
    text-align: center;
    padding: 0 25px;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 45px;

    @media (max-width: 768px) {
        margin-bottom: 13px;
        font-weight: 500;
        font-size: 21px;
        line-height: 22px;
        letter-spacing: -0.03em;
        color: #292929;
        padding: 0 10px;
    }
}

.speaker-card-professional-title {
    color: #20d5c4;
    text-align: center;
    margin: 0 25px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    //display: -webkit-box;
    //display: flex;
    align-items: center;
    justify-content: center;

    max-height: 55px;
    height: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.speaker-card-topics {
    margin: 25px 25px 0;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 52px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.speaker-card-footer {
    width: 224px;
    height: 41px;
    display: flex;
    position: absolute;
    bottom: 0;
    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    background-color: white;

    &__elem {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #e9e9e9;
        color: #797979;
        text-align: center;

        &_first {
            border-right: 1px solid #e9e9e9;
        }

        &_second {
            border-left: 1px solid #e9e9e9;
        }
    }
}