@import '../../../styles/variables';

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.errorBorder {
    border: 1px solid red !important;
}

.title {
    font-size: 24px;
    font-weight: 300;
}

.text {
    font-size: 13px;
}

.submit {
    margin-top: 17px;
}
.errorMsg{
    font-size: 10px !important;
    color: red !important;
    position: absolute;
    top: 0;
    text-align: right;

    @media (max-width: 1024px) {
        font-size: 8px !important;
    }
}
[class="form-group"] {
    margin-bottom: 11px;
}
[class="modal-header"] {
    p {
        font-size: 13px;
    }
}
.close_btn {
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 36px;
    font-weight: 200;
    transform: rotate(45deg);
    cursor: pointer;
}