$primary: #293749;
$aqua: #20d5c4;
$coral: #e8388a;
$greyBG: #eaebed;
$text: #797979;
$semiGreyText: #abb0be;
$secondaryDark: #374658;
$placeholderText: #5d616f;

@media screen and (max-width: 768px) {
  .s-home__speakers-slide {
    position: relative !important;

    .flip-card {
      min-height: 368px !important;
    }
    .flip-card__front .front {
      background: linear-gradient(to bottom, #3c4959 0%, #3c4959 80px, #fff 80px, #fff 100%);
      padding-top: 35px;

      .avatar-wrapper {
        width: 80px;
        height: 80px;

        img {
          height: 80px;
        }
      }
      .card__footer {
        height: 123px;
      }
    }
    .flip-card__front,
    .flip-card__back,
    .flip-card__back > div {
      background-position: center center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: initial;
      margin-bottom: 0;
      width: 100%;
      height: 100%;
    }

    .flip-card__front .front {
      .add-btn {
        margin: 8px 0 17px;
      }
    }
    .flip-card__back > div {
      .add-btn {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .s-home {
    &__container {
      width: 320px;
    }

    &__header {
      padding-top: 100px;
      padding-bottom: 30px;

      & > .s-home__container {
        padding: 10px 14px 0 16px;
      }

      &-title {
        letter-spacing: -0.03em;
        width: 320px;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 10px;
        height: 99px;
        text-align: center;
      }

      &-subtitle {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    &__search {
      &-form {
        justify-content: stretch;
        margin-bottom: 0;
        width: 320px;
      }

      &-label {
        & img {
          width: 16.4px;
          left: 17px;
        }
      }

      &-input {
        width: 244px;
        border-radius: 40px 0 0 40px;
        padding: 15px 50px;
        font-size: 14px;
        line-height: 18px;
        color: #9a92aa;

        &::placeholder {
          font-size: 20px;
          line-height: 18px;
          color: #9a92aa;
        }
      }

      &-submit {
        border-radius: 0 40px 40px 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        padding: 13px 22px;
      }
    }

    &__trending {
      padding: 35px 0 35px 0;

      & .s-home__container {
        flex-direction: column;
        margin-bottom: 14px;
        align-items: start;
      }

      &-title {
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 30px;
        line-height: 30px;
      }

      &-text {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }

    &__speakers {
      flex-direction: column;
      padding: 30px 0 35px 0;
      align-items: center;

      &-title-wrap {
        width: 316px;
        margin-bottom: 13px;
      }

      &-title-container {
        width: 100%;
        flex-direction: column;
        align-items: start;
      }

      &-title {
        width: 100%;
        font-weight: normal;
        font-size: 30px;
        line-height: 30px;
        margin-right: 0;
        margin-bottom: 12px;
      }

      &-subtitle {
        width: 316px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
      }

      &-desc {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }

      &-slide {

      }

      &-button-wrapper {
        margin-bottom: 32px;
      }

      // &-slider {
      //   position: relative;
      //   right: initial;
      //   & .slick-slider > .slick-dots {
      //     bottom: -15px;
      //   }
      // }

      &-link {
        font-size: 12px;
        line-height: 16px;
        padding: 13px 37px;
        margin: 45px 0 0 0;
      }
    }

    &__get-started {
      margin-top: 30px;
    }

    &__types {
      margin-top: 20px;

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 26px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 20px;
        transform: none;

        span {
          width: 320px;
        }
      }

      &-container {
        flex-direction: column;
        row-gap: 15px;
        align-items: center;
        height: unset;
      }

      &-elem-container {
        width: 180px;
        height: 180px;
      }

      &-item {
        width: 180px;
        height: 180px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0;

        & img {
          width: 50px;
          margin-bottom: 0;
        }
        &:hover {
          border: 2px solid $coral;
          transform: scale(1);
        }
      }

      &-name {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        word-break: break-word;
      }

      // &-slide {
      //   padding-right: 12px;
      // }

      &-elem-container {
        &:hover {
          transform: none;
          border: none;
        }
      }
    }

    &__formats {
      padding-top: 40px;
      padding-bottom: 19px;

      &-container {
        width: 320px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      &-elem {
        width: 152px;
        height: 152px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 13px;
        border-radius: 3px;
      }

      &-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        color: #ffffff;
      }

      &-title {
        font-size: 26px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        margin: 0 0 12px 0;
      }

      &-item {
        width: 180px;
        height: 180px;
        word-break: break-word;
        flex-direction: column;
      }

      &-name {
        font-size: 18px;
        line-height: 24px;
      }

      &-wrapper {
        display: block;
        width: 320px;
      }
    }

    &__feadbacks {
      &-title {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 30px;
        text-align: left;
      }
    }

    &__steps {
      padding: 65px 0 30px 0;
      width: 320px;
      margin: auto;

      &-item {
        width: 320px;
      }

      &-pref {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 10px;
      }

      &-title-container {
        margin-bottom: 0;
      }

      &-title {
        width: 100%;
        text-align: center;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 30px;
        letter-spacing: -0.03em;
      }

      &-container {
        width: 100%;
        flex-direction: column;
      }

      &-wrapper {
        max-width: initial;
        min-width: initial;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-name {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 8px;
      }

      &-desc {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      &-link {
        padding: 12px 37px;
        margin: 19px 0 34px 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }

      &-img {
        max-width: initial;
        transform: none;
        position: relative;
        height: 270px;
        top: 0;
        left: 0;
        & > img {
          position: absolute;
          right: -3px;
          top: -47px;
          width: 347px;
          transform: none;
        }
      }

      &-process__container {
        position: relative;
        width: 316px;
        height: 225px;
        margin-top: 20px;

        .process__info {
          text-align: left;
        }

        .process__info-text {
          margin-right: 40px;
        }

        .process__img {
          text-align: right;
          &::after,
          &::before {
            content: "";
            position: absolute;
            border-radius: 50%;
            background-color: #20d5c4;
            z-index: 0;
          }

          &::after {
            width: 27px;
            height: 27px;
            left: 170px;
            bottom: 35px;
          }

          &::before {
            width: 12px;
            height: 12px;
            right: 157px;
            top: 155px;
          }

          &--big {
            width: 221px;
            height: 155px;
            z-index: 1;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            position: absolute;
            left: 20px;
          }

          &--small {
            z-index: 2;
            width: 107px;
            height: 188px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            position: absolute;
            top: 28px;
            right: 37px;
          }
        }
      }
    }
  }
}
