@import './src/styles/mixins.scss';

$grey-color: #293749;
$aqua-color: #20D5C4;
$magenta-color: #E8388A;
$light-grey-color: #E9E9E9;

$mobile-breakpoint: 768px;

.container {
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 100px;

  .mainHeading {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.03em;
    color: #19222D;
  }

  .subheading {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
  }

  .agreement {
    padding: 40px 60px 40px 44px;
  }
}

.container_form {
  box-sizing: border-box;
  margin: 0 auto;
  //padding: 40px 60px 80px 44px;
  @media (max-width: $mobile-breakpoint) {
    padding: 10px 15px 20px 15px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0;
  background: #FFFFFF;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 0 10px;
  }
}

.first_column {
  padding: 53px 47px 87px 62px;
  width: auto;
  @media (max-width: 584px) {
    padding: 0;
  }
}

.second_column {
  width: auto;
  padding: 50px;
  min-width: 450px;
  background-color: $light-grey-color;
  @media (max-width: $mobile-breakpoint) {
    //height: 100%;
    min-width: unset;
  }
}

.event_name {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #19222D;

  @media (max-width: 584px) {
    padding: 10px 10px 10px 10px;
  }

  &_logs {
    font-size: 50px;
  }

  &_head {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
  }

  &_text {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.03em;
  }
}

.brief {
  font-style: normal;
  font-weight: 300;
  padding-top: 20px;
  font-size: 13px;
  line-height: 19px;
  color: #19222D;

  &_head {
    font-weight: 600;
  }

  &_text {
    max-width: 514px;
  }
}

.read_button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  padding-top: 10px;
  text-transform: uppercase;
  color: #E8388A;
  transform: matrix(1, 0, 0, 1, 0, 0);
  cursor: pointer;
}

.read_button:focus {
  outline-style: none;
}

.budget {
  padding-top: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  color: #19222D;
  font-size: 13px;
  &_money {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #20D5C4;
  }
  &_add {
    width: auto;
    height: 26px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #20D5C4;
    cursor: pointer;
    &_notesArea {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    > span {
      padding-top: 5px;
      padding-left: 10px;
    }
    &_imgPlus {
      width: 22px;
      height: 22px;
    }
  }
}

.modalButtonsGroup {
  padding-top: 20px;
}

.additional_notes_text {
  max-width: 300px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #19222D;
  text-align: justify;
}

.additional_notes_textArea {
  width: 520px;
  @media (max-width: 784px) {
    width: 400px;
  }
  @media (max-width: 476px) {
    width: 270px;
  }
}

.offer_form {
  margin-top: 50px;
}

.test_form {
  background: #F1F5F6;
  box-sizing: border-box;
  max-width: 572px;
  height: 902px;
  margin-top: 50px;
}

.your_offer {
  background: #F1F5F6;
  max-width: 572px;
  &_input {
    width: 163px;
    height: 41px;
    border-radius: 6px;
    border-width: 1px;
    @media (max-width: 584px) {
      width: 90px;
      height: 41px;
    }
  }
  &_input::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    float: right;
    padding: 8px 10px;
    @media (max-width: 584px) {
      font-size: 10px;
    }
  }
  &_headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px 0 44px;

    h3, h4 {
      margin: 0;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
    }

    @media (max-width: 584px) {
      padding: 2px 2px 0 2px
    }
  }


  .zigzag {
    height: 70px;
    background: linear-gradient(30deg, white, white 50%, transparent 50%, transparent),
    linear-gradient(-30deg, white, white 50%, transparent 50%, transparent);
    background-size: 36px;
  }
}

.incrementals_area {
  display: flex;
}

.aud {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
  padding-left: 3px;
  padding-right: 3px;
}

.offer_list_roll {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 60px 0 44px;
  &_paid {
    padding: 40px 60px 0 44px;
    @media (max-width: 584px) {
      padding: 30px 2px 0 2px
    }
  }
  &_buttonsGroup {
    padding-top: 20px;
  }
  &_accords {
    padding-top: 20px;
    &::after {
      margin-top: 20px;
      content: '';
      display: block;
      height: 1px;
      background: #DCDFE0;
      @media (max-width: 584px) {
        margin-bottom: 20px;
      }
    }
  }

  .form {
    padding: 25px 60px 0 44px;
  }

  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-bottom: 20px;
  }
  &_checkboxes {
    padding-bottom: 25px;
    &_title {
      font-weight: 300;
      font-size: 10px;
      line-height: 13px;
      color: #19222D;
    }
    &_label {
      font-weight: 300;
      font-size: 10px;
      line-height: 22px;
      padding-left: 10px;
      color: #19222D;
    }
    &_elements {
      display: flex;
      > input {
        margin-right: 10px;
      }
      @media (max-width: 680px) {
        display: flex;
        flex-direction: column;
      }
    }
    .checkboxError {
      color: red;
    }
  }
  @media (max-width: 584px) {
    padding: 2px 2px 0 2px
  }

  &_group {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
  }
  &_first {
    display: flex;
    justify-content: space-between;
  }
  &_elems {
    display: flex;
    justify-content: space-between;
    &_head {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #000000;
    }
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  }
  &_second {
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
  }
  &_third {
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
  }
  &_fourth {
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
  }
  .speakerFeeInput {
    max-width: 160px;
  }
  &_sum {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #000000;
  }
  &_incremental {
    display: flex;
    justify-content: space-between;
    padding: 40px 60px 0 44px;
    @media (max-width: 584px) {
      padding: 2px 2px 0 2px
    }
    &_group {
      text-align: right;
      cursor: pointer;
    }
    &_add {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      letter-spacing: -0.02em;
      color: #20D5C4;
      padding-left: 5px;
      cursor: pointer;
      @media (max-width: 584px) {
        font-size: 10px;
      }
    }
    &_buttonArea {
      display: flex;
      justify-content: right;
      cursor: pointer;
    }
  }
  &_incremental_reverse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 60px 0 44px;
    &_input {
      &_enterArea {
        @media (max-width: $mobile-breakpoint) {
          padding-top: 10px;
        }
      }
      &_descriptionArea {

      }
      &_description {
        width: 279px;
        height: 41px;
        border-radius: 6px;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }
      }
      &_enter {
        width: 163px;
        height: 41px;
        border-radius: 6px;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }
      }
      &_enter::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        float: left;
        padding: 5px 10px;
      }
      &_description::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        float: left;
        padding: 5px 10px;
      }
    }
    @media (max-width: 584px) {
      padding: 2px 2px 0 2px
    }
    &_input_group {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
      }
    }
  }
  &_receive {
    padding: 0 60px 0 44px;
    background: #20D5C4;
    margin-top: 30px;
    height: 100px;
    width: 570px;
    &_textArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    @media (max-width: 584px) {
      padding: 0 2px 0 2px;
    }
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  &::after {
    margin-top: 20px;
    content: '';
    display: block;
    height: 1px;
    background: #DCDFE0;
    @media (max-width: 584px) {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 584px) {
    padding: 2px 2px 0 2px
  }
}

.additional_notes {
  background: #F1F5F6;
  max-width: 572px;
  padding: 22px 31px 7px 27px;
  margin-bottom: 10px;
}

.span_header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}

.span_list {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.modalForm {
  &_buttonsGroup {
    padding-top: 50px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }
}

.addIcon {
  height: 22px;
  width: 22px;
}

.markIcon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-left: 8px;
  @media (max-width: 768px) {
    margin-left: 2px;
  }
}

.buttonsGroup {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;


  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    row-gap: 10px;
  }
}

.buttonColored {
  @include buttonMagenta;
  white-space: nowrap;
}

.buttonColoredGrey {
  @include buttonGray;
  white-space: nowrap;
}

.buttonIncrementals {
  width: 60px;
  height: 30px;
}

.enterButton {
  display: flex;
  margin: 5px 0 0 auto;
  color: white;
  border: 0.5px solid white;
  border-radius: 8px;
  background: #20d5c4;
}

.enterButton:active {
  outline: none;
}

.enterButton:focus {
  outline: none;
}

.incrementalsContainer {
  padding: 25px 60px 0 44px;
  @media (max-width: 584px) {
    padding: 2px 2px 0 2px
  }
}

.incrementalsArea {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}

.incrementalsInputs {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    padding: 10px 5px 5px 10px;
  }

  .incrementalsInputDescription {
    width: 280px;
  }

  .incrementalsInputAmount {
    max-width: 160px;
  }
}

.incrementalsButtonsGroup {
  display: flex;
  justify-content: right;
  padding-top: 5px;
}

.checkButton {
  color: white;
  border: 0.5px solid white;
  border-radius: 8px;
  background: #20d5c4;
}

.checkButton:active {
  outline: none;
}

.checkButton:focus {
  outline: none;
}

.cancelButton {
  color: black;
  border: 0.5px solid white;
  border-radius: 8px;
  background: #E9E9E9;
}

.cancelButton:active {
  outline: none;
}

.cancelButton:focus {
  outline: none;
}

.deleteButton {
  color: white;
  border: 0.5px solid white;
  border-radius: 8px;
  background: #E8388A;
}

.deleteButton:focus {
  outline: none;
}

.deleteButton:active {
  outline: none;
}

.contractWrapper {
  margin-bottom: 30px;
}

.uploadButton {
  @include buttonUpload;
}

.uploadButtonArea {
  padding-bottom: 40px;
}

.readyDocumentArea {
  padding-bottom: 30px;
}

.readyDocument {
  color: $aqua-color;

  &:hover {
    color: black;
  }
}

.instalmentPercentageContainer {
  max-width: 250px;
  margin-top: 15px;
}

.instalmentFirst {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
  @media (max-width: 700px) {
    width: 250px;
  }
  &_column {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    &:last-child {
      padding-top: 7px;
      border-top: 1px solid #DCDFE0;
    }
  }
  &_paid {
    font-weight: 600;
    font-size: 15px;
  }
}

.secondInstalmentHeading {
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.errorMessage {
  margin-top: 10px;
  font-size: 9px;
  color: red;
  text-align: center;
}

.bookingFrozenMessage {
  text-align: center;
  font-weight: 500;
  padding-bottom: 50px;
}

.stroke {
  padding-top: 7px;
  border-bottom: 1px solid #DCDFE0;
}

.asteriskNote {
  padding: 20px 44px 0;
  font-size: 10px;
}