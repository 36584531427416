@import "../../styles/variables.scss";
@media (max-width: 1024px) {
  .mobile-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    z-index: 2;

    &__logo-link {
      // width: 68px;

      @media (max-width: 768px) {
        // width: 30px;
        overflow: hidden;
      }
    }

    img {
      width: 100%;
    }

    img.logo-image {
      max-width: 146px;
    }

    &__icon {
      min-width: 20px;
      max-width: 20px;
      min-height: 14px;
      max-height: 14px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
    }

    &__line {
      box-sizing: border-box;
      height: 0;
      border-top: 2px solid #fff;
      border-radius: 1px;
      transition: 0.3s transform;
      transform-origin: center;

      &--top {
        transform: translate(-2px, 6px) rotate(135deg);
      }

      &--bottom {
        transform: translate(-2px, -6px) rotate(-135deg);
      }
    }

    &__checkbox {
      display: none;
      opacity: 0;
      z-index: -1;

      &:checked ~ .mobile-head__menu {
        transform: translateX(0);
      }
    }

    &__menu {
      position: fixed;
      bottom: 0;
      top: 0;
      width: 360px;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #293749 url("../../assets/footer-bg.webp") center / cover;
      transform: translateX(100%);
      transition: transform 0.4s;
      padding-bottom: 40px;
      z-index: 10000;
      overflow-y: auto;

      @media (max-width: 768px) {
        left: 0;
        width: 100%;
      }

      &-top {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        margin-bottom: 25px;
      }

      & ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 30px 0 152px;
      }

      &-item {
        position: relative;

        &:not(:first-child) {
          margin-top: 40px;
        }

        &.locked {
          &::after {
            position: absolute;
            top: 2px;
            right: -23px;
            content: ' 🔒';
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-link {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: #fff;
        transition: color 0.3s ease-in-out;
      }

      &-link:hover {
        color: #20d5c4;
      }
    }

    &__btns {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: center;
    }

    &__btn {
      height: 38px;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      background: #475668;
      backdrop-filter: blur(40px);
      border-radius: 40px;
      padding: 0 40px;
      margin-bottom: 32px;
      transition: background-color 0.3s ease-in-out;
      display: block;
      line-height: 38px;
      text-align: center;

      &:hover {
        background: #20d5c4;
        color: #fff;
      }
    }
    &__btn--aqua {
      display: table-cell;
      vertical-align: middle;
      height: 47px;
      padding: 0 13px;
      color: white;
      background: #20d5c4;
      border-radius: 40px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__btn--pink {
      display: table-cell;
      vertical-align: middle;
      height: 47px;
      line-height: 47px;
      padding: 0 13px;
      color: white;
      background: #E8388A;
      border-radius: 40px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__login {
      border: none;
      background: none;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #fff;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #20d5c4;
      }
    }
  }
}

.about-getstarted.menu__getstarted {
  padding-bottom: 24px;
  width: 100%;
  @media (max-width: 1200px) {
    .about-getstarted__title {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 40px;
    }
    .about-getstarted__links {
      display: block;
      width: 100%;
      padding: 0 20px;
    }
    .about-getstarted__link {
      width: 100%;
      padding: 40px 0;
      margin-bottom: 10px;
    }
    .about-getstarted__link-name {
      margin-bottom: 0;
      font-size: 28px;
      letter-spacing: 0.97px;
      line-height: 34px;
    }
    .about-getstarted__link-desc {
      margin-top: 12px;
    }

    .about-getstarted__link-btn {
      width: 154px;
      height: 48px;
    }
  }
  @media (max-width: 768px) {
    .about-getstarted__link {
      padding: 40px 45px;
    }
  }
}

.mobile-head__menu {
  @media (max-width: 1200px) {
    .login-link span {
      font-size: 16px;
      line-height: 22px;
      color: #fff;
    }
  }
}
