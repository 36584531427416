@import url(./variables.scss);

$grid-gutter-width: 15px;

@import "~bootstrap/scss/bootstrap.scss";

a {
    &:hover {
        text-decoration: none;
    }
}

.btn {
    border-radius: 50px;
    padding: 6px 20px 6px 20px;
    height: 56px;
    border-width: 2px;
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px;

    &:hover,
    &:focus {
    }

    &-secondary {
        background-color: #16d4c2;
        border-color: #16d4c2;
    }

    &-light {
        background-color: transparent;
        border-color: transparent;
        color: #d0d0d0;
    }

    &-dark {
        border-color: #b9c6ca;
        background: #b9c6ca;

        &:hover,
        &:focus {
            background: #b9c6ca;
            border-color: #b9c6ca;
        }

        &:not(:disabled):not(.disabled):active {
            background: #b9c6ca;
            border-color: #b9c6ca;
            opacity: 0.8;
        }
    }

    &-outline-primary {
        border-color: #ff00ff;
        color: #ff00ff;

        &:hover,
        &:focus {
            background-color: #cc66ff;
            color: white;
            border-color: #cc66ff;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: #cc66ff;
            border-color: #cc66ff;
        }
    }

    &-outline-secondary {
        border-color: #16d4c2;
        color: #16d4c2;

        &:hover,
        &:focus {
            background-color: #16d4c2;
            border-color: #16d4c2;
            color: white;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: #16d4c2;
            border-color: #16d4c2;
        }
    }

    &-outline-future {
        border-color: #06aebf;
        color: #06aebf;

        &:hover,
        &:focus {
            background-color: #06aebf;
            border-color: #06aebf;
            color: white;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: #06aebf;
            border-color: #06aebf;
        }
    }

    &-link {
        color: #212529;
        padding-left: 0;
        padding-right: 0;
        width: auto;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: transparent;
            color: #212529;
            text-decoration: none;
        }

        &:not(:disabled):not(.disabled):active {
        }
    }
}

// form input
.form-control {
    font-weight: 300;
    height: 40px;
    background-color: white;
    border: 1px solid #a4a4a4;
    font-size: 14px;
    border-radius: 5px;
    padding-left: 27px;
    padding-right: 27px;
    color: #10151b;

    &:focus {
        box-shadow: none;
        border-color: #a4a4a4;
    }

    &::-webkit-input-placeholder {
        color: #495057;
    }
    &::-moz-placeholder {
        color: #495057;
    }
    &:-ms-input-placeholder {
        color: #495057;
    }
    &:-moz-placeholder {
        color: #495057;
    }
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
}

// MODAL open
.modal {
    $block-name: &; // #{$block-name}__element {}

    &-dialog {
        max-width: 100%;
    }

    &-content {
        border: none;
        border-radius: 10px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 25px;

        @include media-breakpoint-up(sm) {
            padding-top: 13px;
            padding-left: 31px;
            padding-right: 30px;
        }
    }

    &-header {
        display: inline-block;
        vertical-align: top;
        padding-top: 0;
        flex-direction: column;
        border-bottom: none;
        text-align: center;
        padding-bottom: 8px;

        p {
            font-size: 13px;
            margin-bottom: 0;
        }
    }

    &-title {
        margin-bottom: 8px;
    }

    &-body {
        padding: 19px 0 0px 0;
    }

    &-footer {
        margin-top: 24px;
        border-top: 1px solid #dadada;
        flex-direction: column;
        padding-top: 13px;
        padding-bottom: 0;

        & > :not(:last-child) {
            margin-right: 0;
        }

        p {
            font-size: 16px;
            margin-right: 0 !important;
            margin-bottom: 0px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        a {
            color: var(--lime);
        }
    }

    .close {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &-sm {
        max-width: 90%;

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
            margin-top: 15px;
            margin-bottom: 15px;
            //padding-left: 15px;
            //padding-right: 15px;
        }
        @include media-breakpoint-up(sm) {
            max-width: 432px;
        }
    }

    &-login {
        #{$block-name} {
        }
    }
}
// MODAL close

// FORM start
.form-group {
    margin-bottom: 7px;
}

.form-control {
}

.form-label {
    font-size: 14px;
    margin-bottom: 3px;
    color: var(--dark-standart);
}
// FORM end

// CHECKBOX start
.form-check {
    padding-left: 24px;
    font-size: 13px;
    letter-spacing: -0.1px;
    padding-bottom: 10px;

    input {
        display: none;
    }

    label {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: #dcdcdc;
        }

        span {
            letter-spacing: -0.3px;
        }
    }

    input + label {
        &:before {
            background-image: url(../assets/check.svg);
            background-size: 0;
        }
    }

    input:checked + label {
        &:before {
            background-color: var(--green-primary);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../assets/check.svg);
            background-size: 11px;
        }
    }
}
// CHECKBOX end

.color-promo {
    color: var(--grass);

    &:hover {
        color: var(--grass);
    }
}
