@import './src/styles/mixins.scss';
$mobile-breakpoint: 768px;


.paperContent {
  padding: 40px 50px;

  @media (max-width: $mobile-breakpoint) {
    padding: 40px 10px;
  }
}

.formItem {
  .label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 9px;
    color: black;
  }

  .input {

  }
}

.heading {
  display: flex;
  align-items: center;
  column-gap: 10px;

  .text {
    font-size: 22px;
    line-height: 2;
    font-weight: 500;
  }
}

.subheading {
  padding-top: 10px;
  font-size: 15px;
  color: #10151B;
}

.buttonColored {
  @include buttonMagenta;
}

.buttonColoredGrey {
  @include buttonGray;
}
