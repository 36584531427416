$primary: #293749;

.s-home {
    &__formats {
        padding: 85px 0 79px 0;

        @media (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 19px;
        }
    }

    &__formats-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 224px auto 224px;
        grid-gap: 26px;

        @media (max-width: 768px) {
            display: block;
            width: 320px;
        }
    }

    &__formats-container {
        @media (max-width: 768px) {
            width: 320px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    &__formats-item {
        position: relative;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        transition: transform 0.5s;
        cursor: pointer;

        @media (max-width: 768px) {
            width: 180px;
            height: 180px;
            word-break: break-word;
            flex-direction: column;
        }

        &:hover {
            transform: scale(1.1);
            border: 3px solid #e8388a;
        }
    }

    &__formats-elem {
        @media (max-width: 768px) {
            width: 152px;
            height: 152px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 13px;
            border-radius: 3px;
        }
    }

    &__format {
        position: relative;

        &:hover {
            filter: "grayscale(80%)";
        }
    }

    &__formats-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(10, 5, 26);
        z-index: 2;
        opacity: 0.5;
        border-radius: 5px;
    }

    &__formats-title {
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        margin: 0 0 42px;
        color: $primary;
        letter-spacing: -0.03em;

        @media (max-width: 768px) {
            font-size: 26px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            margin: 0 0 12px 0;
        }
    }

    &__formats-name {
        text-align: center;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        color: #fff;
        margin: 20px 0;
        position: absolute;
        z-index: 3;
        padding: 0 20px;

        @media (max-width: 768px) {
            font-weight: 600;
            font-size: 18px;
            line-height: 19px;
            color: #ffffff;
            position: static;
        }
    }
}
