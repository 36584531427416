.locationInput {
  &:focus {
    outline: none;
  }
}

$dropdownTextPadding: 5px;

.dropdownPlaceholders {
  padding: $dropdownTextPadding;
}

.dropdownSelectedLabels {
  color: white;
  background-color: gray;
  padding: $dropdownTextPadding;
  margin: 0 3px;
  border-radius: 5px;
}
