@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.MobileHeader {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
}
.headroom-wrapper,
.headroom.headroom--unfixed {
    width: 100% !important;
    position: relative !important;
    z-index: 5 !important;
    &.absolute {
        position: absolute!important;
    }
}

.header {
    position: sticky;
    top: -10px;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 30px;
    font-size: var(--font-size-header);
    color: var(--grey-header);
    z-index: 2;
    transition: box-shadow 0.5s;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: var(--dark-standart);
    border-bottom: 1px solid #d1d1d1;

    .header_wrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .avatar {
        height: 50px;
        width: 50px;
        overflow: hidden;
        cursor: pointer;
        img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            max-height: 50px;
            max-width: 50px;
            object-fit: cover;
            margin: auto;
            object-position: 50% 50%;
        }
    }

    &.active {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    }
}

.logo {
    height: 32px;

    @include media-breakpoint-up(md) {
        margin-top: -9px;
        vertical-align: middle;
        //margin-right: 44.6px;
        margin-right: 3px;
    }
}

.getStart {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
        // width: 200px;
    }
    @include media-breakpoint-up(lgg) {
        // width: 230px;
    }
}

.group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.groupOne {
    composes: group;
}

.groupCenter {
    display: flex;
    margin: 0px;
    padding-left: 10px;

    &-link {
        cursor: pointer;
        font-size: 14px;
        letter-spacing: 0;
        color: #10151b;
        margin-left: 35px;

        &.active {
            color: var(--grass);
        }
        &.nocheck {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 95%;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: #ff00ff;
            }
        }
    }
}

.groupTwo {
    composes: group;
    margin-top: 15px;
    padding-left: 0;
    display: none;
    font-size: 14px;
    padding-right: 5px;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    li {
    }
}

.menuGroup {
    composes: group;
}

.headerNav {
    display: none;
    list-style: none;
    padding-left: 37px;
    margin: -1px 0 0 0;
    line-height: 25px;
    font-size: 14px;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    a {
        color: var(--dark-standart);
        position: relative;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background-color: var(--grey-header);
            transition: all 0.5s cubic-bezier(0.26, 0.54, 0.32, 1);
        }

        &:hover,
        &:focus {
            color: var(--grey-header);
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }
    }
}

.group {
    li {
        display: flex;
        text-align: center;
    }
}

.btnMargin {
    margin-right: 25px;
}

.popUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80vw;
    position: absolute;
    top: 10vh;
    right: 54.5px;
    border-radius: 5px;
    padding: 0px 4px 0px 4px;
    background: white;
    color: var(--grey-footer-text);
    font-size: var(--font-size-header);
    overflow: hidden;
    animation-name: fade-in;
    animation-duration: 500ms;
    border: 0.5px rgba(0, 0, 0, 0.1) solid;

    li {
        width: 100%;
        margin: 3px !important;
        padding: 3px 3px 5px 15px;
        border-radius: 1px;
        text-align: left;
        list-style: none;

        &:hover {
            background: var(--grey-lt);
        }
    }
}

.menuIcon {
    font-size: 23px;
    position: absolute;
    color: var(--black-md);

    &:hover {
        color: var(--grey-header);
    }
}

.nameArrow {
    display: inline-block;
    position: relative;
    padding-right: 15px;
    margin-right: 25px;
    cursor: pointer;
    &::after {
        position: absolute;
        content: "";
        height: 7px;
        width: 7px;
        display: block;
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
        border-right: 1.5px solid rgba(0, 0, 0, 0.4);
        transform: rotate(45deg);
        margin-left: 10px;
        bottom: 2px;
        right: 0;
        top: 6px;
    }
}
.menu {
    position: absolute;
    padding: 22px 30px 20px;
    background-color: white;
    border: 1px solid #d7d7d7;
    border-radius: 15px;
    width: 230px;
    font-size: 12px;
    color: #10151b;
    line-height: 32px;
    top: 120%;
    right: 10px;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.1);
    .el {
        cursor: pointer;
        color: #10151b;
        a {
            color: #10151b;
        }
    }
    .line {
        height: 15px;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 10px;
        width: 200px;
        position: relative;
        right: 20px;
    }
    .img_wrapper {
        display: inline-block;
        width: 30px;
        margin-right: 15px;
        text-align: center;
    }
}

.d_head {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    padding-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #a4a4a4;

    &__menu_wrap {
        display: flex;
    }

    &__logo {
        width: 210px;
    }

    &__nav {
        display: flex;
        align-items: center;
        margin: 0;
        list-style: none;
        a + a,
        li + li,
        a + li,
        li + a {
            margin-left: 32px;
        }
    }

    &__link {
        color: #10151b;
        cursor: pointer;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 2px;
            right: -9px;
            width: 10px;
            height: 10px;
            background-color: transparent;
            border-radius: 50%;
        }
        &.has-message:after {
            background-color: #ff00ff;
        }
        &-active {
            color: #16d4c2;
            &:after {
                content: "";
                position: absolute;
                top: 2px;
                right: -8px;
                width: 10px;
                height: 10px;
                background-color: #ff00ff;
                border-radius: 50%;
            }
        }

        &:hover {
            color: white;
        }

        &_dark {
            color: white;
        }
    }

    &__avatar {
        background-color: transparent;
        position: relative;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: #16d4c2;
        }
        &_active {
            background-color: #16d4c2;
        }
        &_outline {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #fff;
        }
        &_img {
            clip-path: circle(50%);
            position: relative;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__profile {
        position: absolute;
        top: 0;
        right: 0;
    }
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.navLinkActive {
    color: white;
}

.messageCountBadge {
    height: 23px;
    line-height: 23px;
    width: 23px;
    position: absolute;
    border-radius: 15px;
    top: -13px;
    z-index: 1;
    right: -23px;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #fb00ff;
    box-shadow: 0 0 5px 1px #ff66ff;
}
