@use '../../styles/mixins.scss' as mixins;
$max-width-content: 1160px;

%block {
  max-width: $max-width-content;
  margin: 60px auto;
}

.content {
  .list {
    list-style: none;
    counter-reset: num;

    li {
      position: relative;
      counter-increment: num;
      margin-top: 40px;

      &::before {
        position: absolute;
        left: -40px;
        content: counter(num) ". ";
        font-weight: 600;
      }

      strong {
        font-weight: 700;
      }
    }
  }

  .ulist {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding-left: 30px;

    li {
      &::marker {
        color: #20d5c4;
      }
    }
  }

  .description {
    margin: 40px 0;
  }

  .speakersBlockWrapper {
    background-color: #293749;
    color: white;
    padding: 1px 0;

    .speakersBlock {
      @extend %block;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        @media (max-width: 768px) {
          flex-direction: column;
          margin: 0 10px;
        }

        .title {
          font-size: 44px;
          line-height: 44px;
          letter-spacing: -0.03em;
          font-weight: normal;
          color: white;
        }

        .subtitle {
          flex: 0 1 400px;
          font-size: 16px;
          line-height: 25px;
          color: white;

          @media (max-width: 768px) {
            flex: 0 1 auto;
          }
        }
      }
    }
  }

  .speakersList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 75px;
    column-gap: 10px;
    margin: 30px 0 50px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .button {
    @include mixins.topicButtonMixin;
    display: inline-flex;
    width: 200px;
    height: 47px;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }

  .buttons {
    display: flex;
    justify-content: center;
    column-gap: 100px;

    @media (max-width: 768px) {
      column-gap: 10px;
    }
  }
  
  .callToActionBlock {
    @extend %block;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 465px;
    padding: 40px 0;
    margin-top: 90px;
    background-image: url('../../assets/topic-page.jpg');
    background-position: center center;
    border-radius: 10px;
    color: white;
    text-align: center;

    h2.top {
      color: white;
      font-size: 50px;
      letter-spacing: -2px;
      text-align: center;
      margin: 0 20px;

      span {
        color: #20d5c4;
      }   
    }

    .bottom {
      h3 {
        color: white;
        font-size: 35px;
        margin: 0;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .howWeHelpBlock {
    @extend %block;
  }

  .whyBlock {
    @extend %block;
  }

  .aboutBlock {
    @extend %block;
    margin-bottom: 100px;

    .aboutContainer {
      background-color: #1db8b0;
      border-radius: 20px;
      padding: 1px 50px;

      @media (max-width: 768px) {
        padding: 1px 20px;
      }

      p {
        font-size: 18px;
        line-height: 1.3;
        color: white;
        margin: 40px 0;
      }

      .link {
        color: #293749;
        text-decoration: underline;

        &:hover {
          color: #293749;
        }
      }
    }
  }
}

.greenText {
  color: #20d5c4;
}

h2.subheading {
  font-size: 44px;
  line-height: 44px;
  letter-spacing: -0.03em;
  font-weight: 500;
  color: #293749;
  text-align: center;
  margin: 40px 20px;
}

p.paragraph {
  color: #293749;
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 40px;

  @media (max-width: 768px) {
    margin: 0 20px 40px;
  }
}

.link {
  color: #20d5c4;
  text-decoration: underline;

  &:hover {
    color: #20d5c4;
  }
}

%greenHeading {
  color: #20d5c4;
  margin: 40px 0 30px;
  font-size: 30px;
  font-weight: 700;

  @media (max-width: 768px) {
    margin: 40px 20px 30px;
  }
}

.greenHeading {
  @extend %greenHeading;
}

.greenHeadingList {
  @extend %greenHeading;

  &::before {
    content: "";
    background-color: #20d5c4;
    width: 1.3em;
    height: 1.7em;
    padding-left: 1.5em;
    mask-image: url(../../assets/logo-microphone.svg);
    -webkit-mask-image: url(../../assets/logo-microphone.svg);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: left center;
    -webkit-mask-position: left center;
  }
}
