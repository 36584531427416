@import '../styles/mixins.scss';

$tabs: #35F4E5;
$tabletBreakpoint: 768px;

.container {
  position: relative;
  max-width: 1110px;
  margin: 0 auto;
}

.controls {
  display: flex;
  justify-content: space-between;
  padding-top: 92px;

  .tabs {

  }

  @media (max-width: $tabletBreakpoint) {
    flex-direction: column-reverse;

  }

  .queryParams {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .searchWrapper {

    }

    .sortWrapper {
      width: 140px;
    }
  }

  .indicator {
    background-color: transparent;
  }
}

.tabFlexContainer {
  height: 52px;
  column-gap: 10px;

  @media (max-width: $tabletBreakpoint) {
    flex-direction: column;
    height: unset;
  }
}

.tabsContent {
  padding-top: 10px;
}

.tab.root {
  color: $dashboardHeader;
  background-color: $tabs;
  opacity: 1;
  text-transform: unset;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (max-width: $tabletBreakpoint) {
    max-width: unset;
    padding-top: 0;
    border-radius: unset;
  }
}

.tab.selected {
  color: $dashboardHeader;
  background-color: $dashboardBackground;

  &:focus {
    outline: unset;
  }
}
