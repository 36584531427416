@import './src/styles/mixins.scss';

$grey-color: #293749;
$aqua-color: #20D5C4;
$magenta-color: #E8388A;
$light-grey-color: #E9E9E9;

$mobile-breakpoint: 768px;

.bookingFormDetails {
  min-height: 100%;
}

.bookingFormDetailsItem {
  color: #000000;
  margin-bottom: 40px;
}

.bookingFormDetailsItemLabel {
  font-size: 13px;
  font-weight: 300;
}

.printEnquiry {
  text-align: right;
  padding: 20px;
}

.bookingFormDetailsItemData {
  font-size: 16px;
  font-weight: 500;
}