$main-color: #293749;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 410px;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  text-align: center;

  .description {
    padding: 15px 15px;
    flex-grow: 1;

    &:hover {
      .backSide {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .backSide {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 40px 15px 0;
      color: white;
      background-color: #E0619D;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      pointer-events: none;

      .tagline {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-size: 14px;
          text-align: center;
        }
      }

      .keynotes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 10px;
        font-weight: normal;
        font-size: 10px;

        .item {
          display: flex;
          align-items: center;
          column-gap: 7px;
          width: 93px;
          height: 38px;
          padding: 0 7px;
          background-color: #EF4C98;
          line-height: 11px;
          border-radius: 5px;
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;

        .link {
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          transition: font-size 0.3s linear 0s;
          color: white;
          text-decoration: underline;

          svg {
            margin-left: 5px;
            vertical-align: baseline;

            path {
              fill: white;
            }
          }

          &:hover {
            font-size: 18px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .avatar {
    width: 100%;
    flex: 0 0 225px;
    overflow: hidden;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: $main-color;
  }

  .professionalTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 10px 0;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1db8b0;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
  }

  .topics {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 10px 0;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $main-color;
    font-size: 12px;
    line-height: 13px;
  }
}
