$elementHeight: 45px;
$disabledColor: #AAAAAA;

.wrapper {
  display: inline-block;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  min-height: $elementHeight;
  padding: 0 20px;
  border: 1px solid black;
  border-radius: 25px;
  color: black;
  background-color: transparent;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  margin: 0 4px;

  &.error {
    border-width: 2px;
    border-color: red;
    margin: 0;
  }

  &.disabled {
    cursor: not-allowed;
    color: $disabledColor;
    border-color: $disabledColor;

    svg {
      fill: $disabledColor;
    }

    label, .empty, .filled {
      cursor: not-allowed;
    }
  }

  .filled {
    cursor: pointer;
    height: 100%;
    width: 100%;

    .filledContent {
      display: flex;
      align-items: center;
      margin: 0 auto;

      .text {
        flex-grow: 1;
        padding-right: 5px;
      }
    }
  }

  label.empty {
    cursor: pointer;
    display: block;
    height: 100%;
    margin: 0;
  }

  .emptyText {
    vertical-align: middle;
  }

  input {
    display: none;
  }
}

.errorText {
  color: red;
}
