$primary: #293749;
$aqua: #20d5c4;
$coral: #e8388a;
$greyBG: #eaebed;
$text: #797979;
$semiGreyText: #abb0be;
$secondaryDark: #374658;
$placeholderText: #5d616f;

.s-home {
  &__slider-arrow-left {
    width: 20px;
    height: 40px;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    top: 140px;
    left: 50px;
  }

  &__slider-arrow-right {
    width: 20px;
    height: 40px;
    top: 140px;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    right: 50px;
  }

  &__container {
    max-width: 975px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__header {
    background-color: $primary;
    padding: 200px 0 188px 0;

    &-title {
      font-style: normal;
      font-size: 80px;
      line-height: 75px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 161px;
      letter-spacing: -0.03em;
    }

    &-subtitle {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 26px;
      color: #ffffff;
      text-align: center;
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    width: 510px;

    &-title {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 11px;
      color: #fff;
      margin: 0 15px 0 0;
      white-space: nowrap;
    }

    &-item {
      min-width: 82px;
      height: 21px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 14px;
      margin: 0 11px 0 0;
      border-radius: 35px;
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
      white-space: nowrap;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__trending {
    padding: 73px 0 47px 0;

    & > .s-home__container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
      flex-direction: row;
      align-items: flex-start;
    }

    &-title {
      font-size: 44px;
      line-height: 44px;
      letter-spacing: -0.03em;
      font-weight: 400;
      color: #293749;
      margin: 0;
    }

    &-text {
      width: 385px;
      margin-top: 3px;
      margin-right: 84px;
      font-weight: 300;
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__trending-search-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  &__trending-search-btn {
    width: 163px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12.32px;
    line-height: 18px;
    background-color: #e8388a;
    border-radius: 35.2px;
  }

  &__feadbacks-title {
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 72px;
    color: #fff;
    text-align: center;
    margin-bottom: 75px;
  }

  &__speakers {
    display: flex;
    flex-direction: column;
    background-color: #293749;
    padding: 72px 0 0 0;

    &-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      margin-bottom: 45px;
    }

    &-button {
      text-transform: uppercase;
      width: 172px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      background: #e8388a;
      border-radius: 35px;
      border: none;
      color: #ffffff;
    }

    &-wrap {
      position: relative;
      width: 100%;
    }

    &-title-wrap {
      display: flex;
      justify-content: center;
      color: #ffffff;
      width: 100%;
      margin-bottom: 45px;
    }

    &-title-container {
      width: 975px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-size: 44px;
      line-height: 44px;
      letter-spacing: -0.03em;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 25px;
      width: 392px;
      margin-right: 83px;
    }

    &-desc {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      color: $text;
      margin: 0 0 40px;
    }

    &-link {
      display: inline-block;
      border-radius: 40px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      background-color: $coral;
      margin: 0 0 60px;
      padding: 23px 52px;
      border: 3px solid transparent;
      transition: border-color 0.3s, background-color 0.3s, color 0.3s;

      &:hover {
        color: $coral;
        background-color: #fff;
        border-color: $coral;
      }
    }

    &-text {
      min-width: 480px;
      width: 480px;
    }

    &-arrows {
      display: flex;
    }

    &-slider {
      width: calc(100% - 200px);
      margin: 0 auto;

      &.drop-width {
        width: 100%;
      }

      &-arrow {
        cursor: pointer;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #fff;

        &:first-of-type {
          margin-right: 20px;
        }
      }
    }

    &-slide {
      padding: 0 0 0 0;
      transition: opacity 0.3s;
      // margin: 0 10px;

      &--inactive {
        opacity: 1;
      }

      & > .flip-card {
        min-height: 562px;
        margin-bottom: 0;
      }
      .flip-card__front {
        & .front {
          height: 562px;
          background: linear-gradient(to bottom, #3c4959 0%, #3c4959 141px, #fff 141px, #fff 100%);
          padding-top: 62px;

          .add-btn {
            margin-bottom: 46px;
          }

          .avatar-wrapper {
            width: 152px;
            height: 152px;

            img {
              height: 152px;
            }
          }
        }
      }
      .flip-card__back {
        .card-footer {
          height: 164px;
        }
      }
    }
  }

  &__get-started {
    margin-top: 60px;
  }

  &__types {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    &-wrapper {
      width: 975px;

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &-title {
      color: #293749;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 40px;
      letter-spacing: -0.03em;
    }

    &-container {
      width: 100%;
      height: 160px;
      display: flex;
      justify-content: space-between;
    }

    &-elem-container {
      width: 140px;
      height: 140px;
      background: #3c4959;
      box-shadow: 0px 15px 50px rgba(167, 171, 172, 0.1);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 15px;
      transition: transform 0.5s ease-in-out;

      &:hover {
        transform: scale(1.2);
        border: 2px solid #e8388a;
      }

      img {
        width: 40px;
      }

      div {
        text-align: center;
        margin-top: 9px;
        color: #ffffff;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
      }
    }

    &-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    & .factor__card {
      box-sizing: border-box;
      width: calc(100% / 3 - 20px);
    }

    &-item {
      height: 263px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 38px;
      background: #ffffff;
      box-shadow: 0 15px 50px rgba(167, 171, 172, 0.1);
      border-radius: 5px;
      margin-bottom: 30px;
      border: 2px solid transparent;
      transition: border 0.5s ease-in-out, transform 0.5s ease-in-out;

      &:nth-of-type(3n-1) {
        margin: 0 30px 30px;
      }

      & img {
        width: 60px;
        height: 60px;
      }

      &:hover {
        border: 2px solid $coral;
        transform: scale(1.1);
      }
    }

    &-name {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      margin: 0;
      color: $primary;
    }
  }

  &__steps {
    padding: 76px 0 78px 0;

    &-container {
      width: 975px;
      display: flex;
      justify-content: space-between;
    }

    &-wrapper {
      max-width: 385px;
      width: 100%;
    }

    &-item {
      margin-top: 24px;
    }

    &-title-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 21px;
    }

    &-title {
      text-align: center;
      color: #293749;
      font-weight: normal;
      font-size: 44px;
      line-height: 44px;
      text-align: center;
      // margin-bottom: 20px;
      letter-spacing: -0.03em;
    }

    &-pref {
      text-transform: uppercase;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: $coral;
      margin: 0 0 15px;
    }

    &-flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-img {
      position: absolute;
      right: -140px;
      top: -45px;
      max-width: 1019px;
      width: 100%;
      min-width: 600px;
      overflow: hidden;
      transform: translate(1px, -49px);

      & img {
        width: 100%;
      }
    }

    &-name {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      color: $primary;
      margin: 0 0 30px 0;
      letter-spacing: -0.03em;
    }

    &-number {
      color: $aqua;
    }

    &-desc {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 21px;
      color: $text;
      margin-top: 10px;
    }

    &-link {
      width: 136px;
      height: 40px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 35px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      background-color: $coral;
      color: #fff;
      border: 3px solid transparent;
      transition: border-color 0.3s, background-color 0.3s, color 0.3s;
      margin-top: 57px;

      &:hover {
        color: $coral;
        background-color: #fff;
        border-color: $coral;
      }
    }

    &-process__container {
      position: relative;
      width: 459px;
      height: 424px;
      margin-top: 5px;

      .process__info {
        text-align: left;
      }

      .process__info-text {
        margin-right: 40px;
      }

      .process__img {
        text-align: right;
        &::after,
        &::before {
          content: "";
          position: absolute;
          border-radius: 50%;
          background-color: #20d5c4;
          z-index: 0;
        }

        &::after {
          width: 45px;
          height: 45px;
          left: 193px;
          bottom: 30px;
        }

        &::before {
          width: 20px;
          height: 20px;
          top: 328px;
          right: 279px;
        }

        &--big {
          width: 412px;
          height: 322px;
          left: -90px;
          top: -20px;
          z-index: 1;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          position: absolute;
        }

        &--small {
          top: 115px;
          right: 41px;
          z-index: 2;
          width: 225px;
          height: 299px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          position: absolute;
        }
      }
    }
  }
}