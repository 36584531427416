@import '../../styles/mixins.scss';

$grey-color: #293749;
$aqua-color: #20D5C4;
$magenta-color: #E8388A;
$light-grey-color: #E9E9E9;

$laptop-breakpoint: 1024px;
$tablet-breakpoint: 992px;
$mobile-breakpoint: 768px;

.container {
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 100px;
}

.pageTitle {
  color: $grey-color;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.03em;
  margin-bottom: 20px;
}

.pageContent {
  display: grid;
  grid-template-columns: minmax(6fr, auto) minmax(auto, 450px);
  grid-template-rows: auto 130px 70px;
  grid-template-areas:
    "description details"
    "actions details"
    "actions contact";

  @media (max-width: $mobile-breakpoint) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "description"
      "details"
      "actions"
      "contact";

    margin: 0 10px;
  }
}

.eventDescription {
  grid-area: description;
  padding-top: 50px;
  
}

.eventDescription, .eventActions {
  background-color: #FFFFFF;
  padding-left: 60px;
  padding-right: 60px;
}

.eventDetails {
  grid-area: details;
  padding-top: 50px;
  background-color: $light-grey-color;
}

.eventDetails, .contactUs {
  padding-left: 50px;
  padding-right: 50px;
}

.eventActions {
  grid-area: actions;
  padding-top: 20px;
}

.contactUs {
  grid-area: contact;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $light-grey-color;
}

.eventDescription, .eventDetails, .eventActions, .contactUs {
  @media (max-width: $mobile-breakpoint) {
    padding: 20px 10px;
  }
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  background-color: #E9E9E9;
  padding-left: 52px;
  padding-top: 50px;
}

.budget {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: $aqua-color;
  margin-bottom: 15px;
}

.subHeader {
  color: $grey-color;
  font-weight: 600;
  font-size: 13px;
}

.eventName {
  color: $grey-color;
  font-weight: 500;
  letter-spacing: -0.03em;
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 24px;
}

.checkBox {
  margin-top: 10px;
  max-width: 327px;

  .errorMessage {
    color: red;
    font-size: 10px;
    min-height: 10px;
    line-height: 10px;
  }
}

.checkboxLabel {
  font-weight: 300;
  font-size: 10px;
}

.personalMessage {
  color: $grey-color;
  max-width: 514px;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  white-space: pre-line;
  margin-bottom: 15px;
  margin-top: 11px;
}

.enquiryDetailsItem {
  color: #000000;
  margin-bottom: 40px;
}

.enquiryDetailsItemLabel {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
}

.enquiryDetailsItemData {
  font-size: 16px;
  font-weight: 500;
}

.printEnquiry {
  text-align: right;
  padding: 20px;
}

.buttonWrapper {
  padding-right: 20px;
}

.buttonTransparent {
  @include buttonGrayTransparent;
}

.buttonColored {
  @include buttonMagenta;
}

.contactUsSection {
  margin-bottom: 40px;
}

.contactUsLink {
  color: $magenta-color;
  padding-left: 10px;
  font-weight: 600;
}

.speaker {
  display: grid;
  grid-template-areas:
    "nameHeader statusHeader statusHeader"
    "name status action";
  row-gap: 10px;
  margin-bottom: 20px;

  @media (max-width: $mobile-breakpoint) {
    grid-template-areas:
      "nameHeader statusHeader"
      "name status"
      "action action";
  }

  .nameHeader {
    grid-area: nameHeader;
  }

  .statusHeader {
    grid-area: statusHeader;
  }

  .name {
    grid-area: name;
    align-self: center;
  }

  .status {
    grid-area: status;
    align-self: center;

    .text {
      padding: 1px 10px;
      color: white;
      background-color: $aqua-color;

      &.pending {
        background-color: $magenta-color;
      }

      &.declined {
        background-color: $darkGray;
      }
    }
  }

  .action {
    grid-area: action;
    align-self: center;
    justify-self: center;
  }
}

.actionSection {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
