$tabletBreakpoint: 768px;

.loadMoreButton {
  background-color: transparent;
  border: 0;
  outline: transparent;

  &:focus {
    @extend .loadMoreButton;
  }
}

.loadMoreContainer {
  text-align: center;
}

.loadMoreText {
  display: none;
  font-size: 20px;
  border: 1px solid;
  padding: 0 10px;
  border-radius: 5px;

  @media (max-width: $tabletBreakpoint) {
    display: inline;
  }
}

.loadMoreImage {
  width: 50px;
  height: 50px;

  &Animated {
    @extend .loadMoreImage;
    -webkit-animation: loaderspin 2s linear infinite; /* Safari */
    animation: loaderspin 2s linear infinite;
  }

  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}

@-webkit-keyframes loaderspin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes loaderspin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
